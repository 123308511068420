@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,100&display=swap');

.main-container {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  background: linear-gradient(
    to right top, #53e3a6, #50a3a2);
    /* background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 300% 300%;
    animation: Gradient 15s ease infinite; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App h1 {
  color: #426696;
  background: -webkit-linear-gradient(45deg, #426696, #43d6d4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App h2, p {
  color: #426696;
  font-weight: 300;
  background: -webkit-linear-gradient(45deg, #426696, #43d6d4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App h5 {
  color: #426696;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  background: -webkit-linear-gradient(45deg, #426696, #43d6d4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App h4 {
  color: #426696;
  font-weight: 600;
  font-size: 25px;
  margin: 0;
  background: -webkit-linear-gradient(45deg, #426696, #43d6d4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.App h3 {
  color: #426696;
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #426696, #43d6d4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.glass {
  display: flex;
  background: white;
  max-height: 80vh;
  width: 80%;
  background: linear-gradient(
    to right bottom, 
    rgba(255, 255, 255, 0.7), 
    rgba(255, 255, 255, 0.2)
  );
  border-radius: 2rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 2;
  overflow: auto;
}

.glass::-webkit-scrollbar {
  display: none;
}

/* .glass::-webkit-scrollbar-thumb {
  background-color: rgb(184, 184, 190);
  border-radius: 20px;
} */

.circle-definition {
  background: white;
  background: linear-gradient(
    to right bottom, 
    rgba(255, 255, 255, 0.8), 
    rgba(255, 255, 255, 0.3)
  );
  position: absolute;
  border-radius: 50%;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  bottom: -160px;

  -webkit-animation: circle 25s infinite;
  animation:         circle 25s infinite;
  
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.circle1 {
  left: 10%;
}

.circle2 {
  left: 20%;
  
  width: 80px;
  height: 80px;
  
  animation-delay: 2s;
  animation-duration: 17s;
}

.circle3 {
  left: 25%;
  animation-delay: 4s;
}

.circle4 {
  left: 40%;
  width: 60px;
  height: 60px;
  
  animation-duration: 22s;
  
  background-color: fade(white, 25%);
}

.circle5 {
  left: 70%;
}

.circle6 {
  left: 80%;
  width: 120px;
  height: 120px;
  
  animation-delay: 3s;
  background-color: fade(white, 20%);
}

.circle7 {
  left: 32%;
  width: 160px;
  height: 160px;
  
  animation-delay: 7s;
}

.circle8 {
  left: 55%;
  width: 20px;
  height: 20px;
  
  animation-delay: 15s;
  animation-duration: 40s;
}

.circle9 {
  left: 25%;
  width: 10px;
  height: 10px;
  
  animation-delay: 2s;
  animation-duration: 40s;
  background-color: fade(white, 30%);
}

.circle10 {
  left: 90%;
  width: 160px;
  height: 160px;
  
  animation-delay: 11s;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.user h5 {
  margin: 16px 0;
}

.user h4 {
  margin: 10px 0;
}

.user img {
  max-height: 150px;
  max-width: 150px;
  border: double 4px transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, rgb(70, 69, 69),rgb(219, 219, 219));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.dashboard {
  position: sticky;
  top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  background: linear-gradient(
    to right bottom, 
    rgba(255, 255, 255, 0.7), 
    rgba(255, 255, 255, 0.2)
  );
  border-radius: 2rem;
}

.contact-dropdown {
  display: none;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.content {
  flex: 2;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
}

.links {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.github, .linkedin {
  width: 50px;
  height: 50px;
}

.dropdown {
  display: none;
}

@-webkit-keyframes circle {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-900px) /*rotate(600deg)*/; }
}
@keyframes circle {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-900px) /*rotate(600deg)*/; }
}

/* intro section */

.intro {
  min-height: 80vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro h3 {
  font-weight: 600;
  font-size: 20px;
}

.intro-fade {
  opacity: 0;
  position: relative;

  animation: words 2s ease-out;
  -webkit-animation: words 2s ease-out;
  
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes words {
  0% {bottom: -20px; opacity: 0;}
  100% {bottom: 0; opacity: 1;}
}
@-webkit-keyframes words {
  0% {bottom: -20px; opacity: 0;}
  100% {bottom: 0; opacity: 1;}
}

.intro1 {
  animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
}

.intro2 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

.intro3 {
  animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
}

.intro4 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

.intro5 {
  animation-delay: 1.25s;
  -webkit-animation-delay: 1.25s;
}

/* skill section */

.skills {
  min-height: 80vh;
}

.skill-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  position: relative;
  align-content: space-evenly;
}

.skill-icon {
  padding: 20px;
}

/* projects section */

.projects {
  min-height: 80vh;
  
}

.projects-container {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  position: relative;
  justify-content: space-evenly;
  align-content: space-evenly;
  align-items: center;
}
 
/* project section */

.project {
  height: 200px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 2rem;
  margin: 10px;
  transition:  0.5s ease-out;
}

.project p {
  font-weight: 500;
}

.project a {
  position: relative;
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.project-name {
  opacity: 1;
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
  transition: .5s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.project-description {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  text-align: center;
}

.project-description p {
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #f79502, #43d6d4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project:hover {
  background: rgba(0, 0, 0, 0.3);
}

.project:hover .project-description {
  opacity: 1;
}

.project:hover .project-name {
  opacity: 0;
}

/* farewell section */

.farewell {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.farewell-linkedin-div {
  height: 35px;
  width: 35px;
}

@media screen and (max-width: 700px), screen and (max-height: 540px) {

  .dashboard p {
    margin: 3px;
    font-size: 12px;
    font-weight: 600;
  }

  .dashboard h4, h5 {
    font-size: 14px;
    font-weight: 600;
  }

  .main-container {
    display: block;
  }

  .glass {
    flex-direction: column;
    max-height: 97vh;
    width: 100%;
    align-items: center;
    background: transparent;
    border-radius: 0;
    -webkit-backdrop-filter: initial;
    backdrop-filter: initial;
  }

  .dashboard {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 10%;
    margin-bottom: 3%;
    padding: 0.5%;
    flex: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2));
    border-radius: 0 0 1.5rem 1.5rem;
    z-index: 3;
  }

  .content {
    z-index: 2;
  }

  .mobile-content {
    display: flex;
    flex-direction: column;
    background: white;
    max-height: 80vh;
    margin: auto;
    width: 90%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: linear-gradient(
      to right bottom, 
      rgba(255, 255, 255, 0.7), 
      rgba(255, 255, 255, 0.2)
    );
    border-radius: 2rem;
    z-index: 2;
    overflow: auto;
  }

  .mobile-content::-webkit-scrollbar {
    display: none;
  }

  .user {
    display: contents;
  }

  .user img {
    height: 15%;
    width: 15%;
    border: double 2px transparent;
  }

  .contact-me {
    display: none;
  }

  .contact-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .contact-info h3 {
    display: none;
  }

  .links {
    width: 20%;
    /* padding: 10px; */
    display: flex;
    justify-content: space-evenly;
  }

  .circle-definition {
    bottom: -30px;
  }

  .skill-grid {
    grid-template-columns: auto auto;
  }

  /* drop down menu */

  .drop-btn {
    display: block;
    border: none;
    border-radius: 2rem;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2));
    cursor: pointer;
    width: 106px;
    height: 25px;
    animation: grow 0.5s ease forwards;
    -webkit-animation: grow 0.5s ease forwards;
  }

  .drop-btn::after {
    content: "Contact info";
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    background: -webkit-linear-gradient(45deg, #426696, #43d6d4);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @keyframes grow {
    0% {opacity: 0; width: 15px; height: 15px;}
    100% {opacity: 1; width: 106px; height: 25px;}
  }
  @-webkit-keyframes grow {
    0% {opacity: 0; width: 15px; height: 15px;}
    100% {opacity: 1; width: 106px; height: 25px;}
  }

  .btn-clicked {
    content: url('./images/xSymbol.png');
    width: 15px;
    height: 15px;
    padding: 5px;
    animation: shrink 0.5s ease forwards;
    -webkit-animation: shrink 0.5s ease forwards;
  }

  @keyframes shrink {
    0% {opacity: 0; width: 106px; height: 25px;}
    40% {opacity: 0;}
    100% {opacity: 1; width: 15px; height: 15px;}
  }
  @-webkit-keyframes shrink {
    0% {opacity: 0; width: 106px; height: 25px;}
    40% {opacity: 0;}
    100% {opacity: 1; width: 15px; height: 15px;}
  }

  .drop-btn:hover, .drop-btn:focus {
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4));
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 0;
    top: 40px;
    z-index: 3;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: linear-gradient(
      to right bottom, 
      rgba(255, 255, 255, 0.9), 
      rgba(255, 255, 255, 0.5)
    );
    border-radius: 2rem;
    animation: appear 0.5s ease forwards;
  }

  @keyframes appear {
    0% {opacity: 0;}
    40% {opacity: 0;}
    100% {opacity: 1;}
  }
  @-webkit-keyframes appear {
    0% {opacity: 0;}
    40% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  .dropdown-content a {
    padding: 12px 16px;
  }

  .dropdown-content p {
    font-size: 15px;
    margin: 10px;
  }

  .drop-links {
    display: flex;
    justify-content: space-evenly;
  }
  
  .dropdown a:hover {
    /* background-color: #ddd; */
    background: linear-gradient(
      to right bottom, 
      rgba(255, 255, 255, 0.9), 
      rgba(255, 255, 255, 0.7)
    );
    border-radius: 1.5rem;
  }
  
  .show {
    display: block !important;
  }

  .hide {
    display: none !important;
  }

  
}





/* @media (prefers-reduced-motion: no-preference) {
  .circle1 {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
